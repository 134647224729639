<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="120px">
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="用户名" prop="userName">
            <el-input v-model="form.userName" placeholder="请输入用户名" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" placeholder="请输入密码" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="姓名/描述" prop="userDesc">
            <el-input type="textarea" :rows="2" v-model="form.userDesc" placeholder="请输入姓名/描述信息" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="用户角色" prop="roleIds">
            <el-input v-model="form.roleIds" placeholder="请选择用户角色" auto-complete="off" :disabled="true">
              <el-button type="primary" slot="append" icon="el-icon-search" @click="selectRoleIdList()"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="数据范围" prop="viewAllData">
            <el-radio-group v-model="form.viewAllData">
              <el-radio :label="true">所有数据</el-radio>
              <el-radio :label="false">机构数据</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据分区编码" prop="dataTenant" v-if="form.viewAllData === false">
            <el-input v-model="form.dataTenant" placeholder="请输入数据分区编码" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="选择用户角色" :visible.sync="visible" width="40%" :close-on-click-modal="false">
      <div>
        <el-table :data="tableData" ref="singleTable" highlight-current-row border size="medium" :height="height" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="roleName" label="角色名称" align="center"></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AddOrEdit',
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() //选当前时间之前的时间
        },
      },
      siteStatusList: [],
      natureList: [],
      radio: '',
      height: 240,
      tableData: [],
      visible: false,
      titleName: '',
      form: {
        viewAllData: true,
      },
      rules: {
        userName: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        dataTenant: [{ required: true, message: '请输入数据分区编码', trigger: 'blur' }],
      },
      sexList: [
        {
          value: '男',
          label: '男',
        },
        {
          value: '女',
          label: '女',
        },
      ],
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['addSystemUser', 'getRoleList']),
    handleSelectionChange(val) {
      let roleNameLst = []
      let roleIdLst = []
      if (val && val.length > 0) {
        for (let i = 0; i < val.length; i++) {
          roleNameLst.push(val[i]['roleName'])
          roleIdLst.push(val[i]['roleId'])
        }
      }
      this.form.roleIds = roleNameLst.join(',')
      this.form.roleIdList = roleIdLst
    },
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    initView() {
      this.getDetail()
      this.getRoleLists()
    },
    search() {
      this.getRoleLists()
    },
    getRoleLists() {
      let server = 'getRoleList'
      this[server]().then((res) => {
        if (res.code == 200) {
          this.tableData = res.data
        }
      })
    },
    getDetail() {
      this.titleName = '添加用户'
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          let server = 'addSystemUser'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '添加成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    selectRoleIdList() {
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    formatStatus(row, column) {
      let msg = row.cnStatus
      for (let i = 0; i < this.siteStatusList.length; i++) {
        if (row.cnStatus === this.siteStatusList[i].dictPidVal) {
          msg = this.siteStatusList[i].dictDesc
        }
      }
      return msg
    },
    formatNature(row, column) {
      let msg = row.nature
      for (let i = 0; i < this.natureList.length; i++) {
        if (row.nature === this.natureList[i].dictPidVal) {
          msg = this.natureList[i].dictDesc
        }
      }
      return msg
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow-y: auto;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .dialog-footer {
    text-align: center;
  }
}
</style>
